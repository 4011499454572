<script lang="ts">
import { Vue, Component, VModel, Prop } from "vue-property-decorator";
import type { InvoiceData } from "@planetadeleste/vue-mc-gw";
import useInvoice from "@/composables/invoice";
import { EventBus } from "@/services/event-bus";
import GwDialog from "@/components/common/GwDialog.vue";
import DateRange from "@/components/form/fields/DateRange.vue";
import AccountingMovementTypeSelect from "@/modules/accountingentries/components/AccountingMovementTypeSelect.vue";

@Component({
  components: { AccountingMovementTypeSelect, DateRange, GwDialog },
})
export default class InvoiceRyaCancelDialog extends Vue {
  @VModel({ type: Boolean, default: false }) dialog!: boolean;
  @Prop(Object) readonly invoice!: InvoiceData;

  loading = false;
  sReason: string | null = null;

  onClose() {
    this.loading = false;
    this.sReason = null;
    this.$emit("input", false);
  }

  async onSend() {
    if (!this.sReason || !this.invoice || !this.invoice.id) {
      return;
    }

    const sMessage = this.$t("ask.cancel.invoice");
    const bRes = await this.$confirm(sMessage as string, {
      color: "warning",
    });

    if (!bRes) {
      return;
    }

    this.loading = true;
    const { obj } = useInvoice(this.invoice);
    await obj?.cancel(this.sReason);
    this.loading = false;

    // Refresh index
    EventBus.emit("reload.index");

    // Close dialog
    this.onClose();
  }
}
</script>

<template>
  <gw-dialog v-model="dialog" :loading="loading">
    <template #title>
      <v-card-title>
        <span class="text-h5">{{ $t("cancel.rya.invoice") }}</span>
      </v-card-title>
    </template>

    <v-form v-if="dialog" class="fill-height" @submit.prevent>
      <gw-form-observer
        :loading="loading"
        hide-bottom-actions
        hide-top-actions
        no-nest
        @cancel="onClose"
        @save="onSend"
      >
        <v-row>
          <v-col cols="12">
            <v-textarea v-model="sReason" hide-details outlined />
          </v-col>
        </v-row>
      </gw-form-observer>
    </v-form>

    <template #prepend-actions>
      <v-btn
        :disabled="!sReason?.length"
        :loading="loading"
        color="primary"
        text
        @click="onSend"
        v-text="$t('send')"
      />
    </template>
  </gw-dialog>
</template>

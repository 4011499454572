<template>
  <gw-dialog v-model="dialog" @open:dialog="onOpen" @close:dialog="onClose">
    <v-card v-if="dialog">
      <v-card-text v-if="sXml" class="pa-0">
        <vue-code-highlight language="xml">
          <pre v-text="sXml" />
        </vue-code-highlight>
      </v-card-text>
    </v-card>

    <template #prepend-actions>
      <invoice-xml-download-btn :uuid="uuid" :xml="sXml" :filename="filename" />
    </template>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

import "vue-code-highlight/themes/prism-tomorrow.css";
import { component as VueCodeHighlight } from "vue-code-highlight";

import GwDialog from "@/components/common/GwDialog.vue";
import InvoiceXmlDownloadBtn from "@/modules/invoices/components/InvoiceXmlDownloadBtn.vue";
import useInvoice from "@/composables/invoice";
import { isString } from "lodash";
import { FlashModule } from "@/store/flash";

@Component({
  components: { InvoiceXmlDownloadBtn, GwDialog, VueCodeHighlight },
})
export default class InvoiceXmlDialog extends Vue {
  @VModel({ type: Boolean, default: false }) dialog!: boolean;
  @Prop([String, Number]) readonly uuid!: string;
  @Prop(String) readonly filename!: string;

  sXml = "";

  async onOpen() {
    try {
      const { xml } = useInvoice();
      this.sXml = (await xml(this.uuid)) as string;
    } catch (e: any) {
      if (e.response) {
        let obResponse = await e.response.response.data.text();
        if (isString(obResponse)) {
          obResponse = JSON.parse(obResponse);
          if (obResponse.message) {
            FlashModule.error(obResponse.message);
          }
        }
      }
    }
  }

  onClose() {
    this.sXml = "";
  }
}
</script>
